import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';

import { ResidentialFormProps } from '../ResidentialForm.types';

interface PaperlessBillingSectionProps extends ResidentialFormProps {
  classes: any;
}

const PaperlessBillingSection: FunctionComponent<PaperlessBillingSectionProps> = props => {
  const { t } = useTranslation();
  const { classes, form } = props;

  return (
    <RadioGroup
      aria-label={'create-residential-phone-number-account-number'}
      name="paperlessBilling"
      value={form.values.paperlessBilling}
      onChange={form.onChange}
      style={{ padding: '1em' }}
    >
      <Grid container spacing={1}>
        <Typography
          color={'textPrimary'}
          className={classes.colorTextPrimary}
          variant={'h2'}
          style={{ marginBottom: 24, marginTop: 20, width: '100%' }}
        >
          {t('CHOOSE_YOUR_PREFERENCES')}
        </Typography>
        <Typography>{t('PAPERLESS_BILL_SENTENCE_CASE')}</Typography>
        <Grid item xs={12} style={{ width: '15em' }}>
          <FormControlLabel
            value="true"
            control={<Radio color={'primary'} />}
            label={t('ENABLE_PAPERLESS_BILLING')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value="false"
            control={<Radio color={'primary'} />}
            label={t('DISABLE_PAPERLESS_BILLING')}
          />
        </Grid>
      </Grid>
    </RadioGroup>
  );
};

export default PaperlessBillingSection;
