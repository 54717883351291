import Typography from '@material-ui/core/Typography';
import React from 'react';

import { useTranslation } from '../../hooks/useTranslation';
import AccountTextField from '../account-text-field';
import PhoneTextField from '../phone-text-field';
import AlphaNumericTextField from '../alpha-numeric-text-field';
import PinTextField from '../pin-text-field';
import { ResidentialFormProps } from '../residential-form/ResidentialForm.types';
import SampleBill from '../sample-bill';
import EitherField from '../either-field';

interface AccountSectionProps extends ResidentialFormProps {
  classes: any;
}

const AccountSection = (props: AccountSectionProps) => {
  const { t } = useTranslation();
  const { classes } = props;
  const { form } = props;

  return (
    <div style={{ padding: '1em' }}>
      <EitherField
        aria-label="create-residential-phone-number-account-number"
        form={form}
        selectorField="primaryAccountOption"
        inputFields={['phoneNumber', 'accountNumber']}
        renderLeft={
          <PhoneTextField
            autoFocus={form.values.primaryAccountOption === 'phoneNumber'}
            data-testid="create-residential-phone-number"
            name="phoneNumber"
            label={t('PHONE_NUMBER')}
            autoComplete="telephone"
          />
        }
        renderRight={
          <AccountTextField
            autoFocus={form.values.primaryAccountOption === 'accountNumber'}
            data-testid="create-residential-acccount-number"
            name="accountNumber"
            label={t('ACCOUNT_NUMBER')}
            autoComplete={t('ACCOUNT_NUMBER')}
          />
        }
        rightHelper={
          <div className={classes.radioSubtitle}>
            <SampleBill tabIndex={-1} />
          </div>
        }
      />

      <Typography color="textPrimary" variant="body1" className={classes.and}>
        {t('AND')}
      </Typography>

      <EitherField
        aria-label="create-residential-ssn-dl"
        form={form}
        selectorField="secondaryAccountOption"
        inputFields={['ssn', 'driversLicense']}
        renderLeft={
          <PinTextField
            className={classes.overflowField}
            data-testid="create-residential-ssn"
            name="ssn"
            label={t('FORGOT_PASSWORD_LAST_FOUR_SSN')}
            autoComplete="ssn"
            inputProps={{ minLength: 4, maxLength: 4 }}
          />
        }
        renderRight={
          <AlphaNumericTextField
            className={classes.overflowField}
            data-testid="create-residential-drivers-license"
            name="driversLicense"
            label={t('DL_OR_STATE_ID')}
            autoComplete={t('DL_OR_STATE_ID')}
            type="text"
            inputProps={{ minLength: 4, maxLength: 4 }}
          />
        }
      />
    </div>
  );
};

export default AccountSection;
