import withWidth from '@material-ui/core/withWidth';
import React from 'react';
import AuthLayout from '../../components/auth-layout';
import ResidentialForm from '../../components/residential-form';
import useResidentialForm from '../../hooks/useResidentialForm';
import { useTranslation } from '../../hooks/useTranslation';
import { RegistrationPageProps } from './index';
import { useLocation } from '@reach/router';
import { useIsMobile } from '../../util/style-utils';

const CreateResidentialPage = (props: RegistrationPageProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const location = useLocation();
  const locationState = location.state as any;
  const defaultAccountNumber = locationState?.accountNumber;
  const redirectUrl = locationState?.redirectUrl;
  const form = useResidentialForm(defaultAccountNumber, redirectUrl);

  const title = t('REGISTRATION_HEADER');

  return (
    <AuthLayout title={title} includePrivacyAgreement showLeft={false}>
      <ResidentialForm form={form} isMobile={isMobile} {...props} />
    </AuthLayout>
  );
};

export default withWidth()(CreateResidentialPage);
