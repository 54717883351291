import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { navigate } from '@reach/router';
import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';

import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import AccountSection from '../account-section';
import Button from '../buttons';
import UserAccountSection from '../user-account-section';
import PaperlessBillingSection from './paperless-billing-section';
import useStyles from './ResidentialForm.styles';
import ResidentialFormProps from './ResidentialForm.types';

interface Props extends ResidentialFormProps {
  redirectUrl?: string;
}
const ResidentialForm: FunctionComponent<Props> = props => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { form, redirectUrl } = props;

  return (
    <>
      <Paper component={'main'} style={{ justifyContent: 'flex-start' }}>
        <Card className={classes.card}>
          <CardContent>
            <Typography
              className={classes.colorTextPrimary}
              component={'span'}
              color={'textPrimary'}
              variant={'h2'}
            >
              {t('CREATE_RESIDENTIAL_SUBTITLE')}
            </Typography>
          </CardContent>

          <form
            className={classes.root}
            noValidate
            onSubmit={form.handleSubmit}
          >
            <FormControl component={'fieldset'} style={{ width: '100%' }}>
              <AccountSection {...props} classes={classes} />
              <hr />
              <UserAccountSection {...props} classes={classes} />
              <hr />
              <PaperlessBillingSection {...props} classes={classes} />
            </FormControl>
          </form>

          <Grid
            container
            justify="flex-end"
            alignContent="center"
            spacing={2}
            alignItems="center"
          >
            <Link
              className={classes.backLinkDesktop}
              to={ROUTES.REGISTRATION}
              onClick={async e => {
                e.preventDefault();
                await navigate(ROUTES.REGISTRATION_CREATE_RESIDENTIAL, {
                  state: { redirectUrl: redirectUrl },
                });
              }}
            >
              {t('CANCEL')}
            </Link>
            <Button
              variant={'contained'}
              color={'primary'}
              className={classes.submit}
              onClick={form.handleSubmit}
            >
              {t('REGISTER')}
            </Button>
            {/* This is for mobile display diff, could float as alternative */}
            <Link
              className={classes.backLinkMobile}
              to={ROUTES.REGISTRATION}
              onClick={async e => {
                e.preventDefault();
                await navigate(ROUTES.REGISTRATION_CREATE_RESIDENTIAL, {
                  state: { redirectUrl: redirectUrl },
                });
              }}
            >
              {t('CANCEL')}
            </Link>
          </Grid>
        </Card>
      </Paper>
    </>
  );
};

export default ResidentialForm;
