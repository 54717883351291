import React, { useContext, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { Link, navigate } from 'gatsby';
import { useTranslation } from './useTranslation';

import validationRules, {
  Model,
} from '../components/residential-form/ResidentialForm.rules';
import { registerResidentialMutation } from '../queries/registration.query';
import ROUTES from '../routes';
import { parsePhoneNumber } from '../util/format';
import useFormState from './useFormState';
import {
  MutationRegisterResidentialArgs,
  RegistrationResponse,
} from '../__generated__/pge-types';
import { TransitionContext } from '../providers/TransitionProvider';
import useAuth from './useAuth';
import { NotificationsContext } from '../providers/NotificationsProvider';

export const ResidentialRegistrationErrors = Object.freeze({
  Success: 'Success',
  ProvidedAccountNotFound: 'ProvidedAccountNotFound',
  ProvidedPhoneOrEmailNotFound: 'ProvidedPhoneOrEmailNotFound',
  NoPersonsFound: 'NoPersonsFound',
  SpecialHandling: 'SpecialHandling',
  AccountAlreadyRegistered: 'AccountAlreadyRegistered',
  WebRegisteredEmailFound: 'WebRegisteredEmailFound',
  AccountLocked: 'AccountLocked',
});

export const defaultFormValues: Model = {
  email: '',
  password: '',
  phoneNumber: '',
  accountNumber: '',
  ssn: '',
  driversLicense: '',
  pinCode: '',
  paperlessBilling: 'true',
  primaryAccountOption: 'phoneNumber',
  secondaryAccountOption: 'ssn',
};

export default function useResidentialForm(
  defaultAccountNumber?: string,
  redirectUrl?: string,
) {
  const { signInWithCustomToken } = useAuth();
  const defaultPrimaryAccountOption = Boolean(defaultAccountNumber)
    ? 'accountNumber'
    : 'phoneNumber';
  const form = useFormState(
    {
      ...defaultFormValues,
      accountNumber: defaultAccountNumber || '',
      primaryAccountOption: defaultPrimaryAccountOption,
    },
    { validate: validationRules },
  );
  const { setState: updateTransition } = useContext(TransitionContext);
  const errorCount = useRef(0);
  const { t, richT } = useTranslation();
  const notificationContext = useContext(NotificationsContext);

  const HandleError = (errorMessage: any) => {
    notificationContext.setState({
      isOpen: true,
      message: errorMessage,
      severity: 'error',
    });
    typeof window !== 'undefined' && window.scrollTo(0, 0);
  };

  const onError = (error: ApolloError) => {
    console.error('residential form error', error);
    updateTransition({ open: false });

    errorCount.current += 1;
    if (errorCount.current > 2) {
      return navigate(ROUTES.REGISTRATION_SPECIAL_HANDLING);
    }

    const err =
      error.graphQLErrors.length &&
      error.graphQLErrors[0].extensions?.debug.message;
    switch (err) {
      case ResidentialRegistrationErrors.ProvidedAccountNotFound:
        if (form.values.primaryAccountOption === 'accountNumber') {
          form.setError(
            form.values.primaryAccountOption as keyof Model,
            t('ERROR_ACCOUNT_NUMBER_REQUIRED'),
          );
        } else {
          form.setError(
            form.values.primaryAccountOption as keyof Model,
            t('PHONE_NUMBER_NOT_FOUND'),
          );
        }
        break;
      case ResidentialRegistrationErrors.ProvidedPhoneOrEmailNotFound:
        form.setError(
          form.values.primaryAccountOption as keyof Model,
          t('PHONE_NUMBER_NOT_FOUND'),
        );
        break;
      case ResidentialRegistrationErrors.NoPersonsFound:
        form.setError(
          form.values.primaryAccountOption as keyof Model,
          form.values.primaryAccountOption === 'phoneNumber'
            ? t('PERSONAL_ID_NO_MATCH')
            : t('PERSONAL_ID_NO_ACCOUNT_NUMBER_MATCH'),
        );
        break;
      case ResidentialRegistrationErrors.SpecialHandling:
        return navigate(ROUTES.REGISTRATION_SPECIAL_HANDLING);
      case ResidentialRegistrationErrors.AccountAlreadyRegistered:
        form.setError(
          'email',
          <span>
            {t('ALREADY_REGISTERED_1')}{' '}
            <Link to={ROUTES.SIGN_IN}>{t('SIGN_IN')}</Link>{' '}
            {t('ALREADY_REGISTERED_2')}
          </span>,
        );
        break;
      case ResidentialRegistrationErrors.WebRegisteredEmailFound:
        form.setError(
          'email',
          <span>
            {t('ALREADY_REGISTERED_SERVICES_1')}{' '}
            <Link to={ROUTES.SIGN_IN}>{t('SIGN_IN')}</Link>{' '}
            {t('ALREADY_REGISTERED_SERVICES_2')}
          </span>,
        );
        break;
      case ResidentialRegistrationErrors.AccountLocked:
        HandleError(
          richT('REGISTRATION_ATTEMPTS_EXCEEDED_DAILY_LIMIT', {
            CUSTOMER_SERVICE_NUMBER: `tel:${t('CALL_FOR_ASSISTANCE_NUMBER')}`,
          }),
        );
        break;
      default:
        HandleError(t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'));
    }
    typeof window !== 'undefined' && window.scrollTo(0, 0);
  };

  const onSuccess = async (uid: string, signInToken: string, email: string) => {
    await signInWithCustomToken(signInToken);
    await updateTransition({ open: false });

    if (redirectUrl) {
      return navigate(redirectUrl);
    }

    return navigate(ROUTES.REGISTRATION_COMPLETE, {
      state: {
        uid,
        signInToken,
        email,
      },
      replace: true,
    });
  };

  const [registerAccount] = useMutation<
    { registerResidential: RegistrationResponse },
    MutationRegisterResidentialArgs
  >(registerResidentialMutation, {
    variables: {
      payload: {
        AccountNumber:
          form.values.primaryAccountOption === 'accountNumber'
            ? form.values.accountNumber
            : '',
        EmailAddress: form.values.email,
        Password: form.values.password,
        PhoneNumber:
          form.values.primaryAccountOption === 'phoneNumber'
            ? parsePhoneNumber(form.values.phoneNumber)
            : '',
        PinCode: form.values.pinCode,
        IsPaperlessBillingSelected: form.values.paperlessBilling === 'true',
        Last4DigitsOfSSN:
          form.values.secondaryAccountOption === 'ssn' ? form.values.ssn : '',
        Last4DigitsOfDriversLicenseOrStateId:
          form.values.secondaryAccountOption === 'driversLicense'
            ? form.values.driversLicense
            : '',
      },
    },
    errorPolicy: 'all',
    onError,
    onCompleted: response => {
      console.info('Register Residential UserAccount Api Response', response);
      if (response?.registerResidential.success) {
        const { uid, signinToken } = response.registerResidential;
        return onSuccess(uid!, signinToken!, form.values.email);
      }
    },
  });

  const handleSubmit = form.submit(
    async () => {
      updateTransition({ open: true });
      await registerAccount();
    },
    errors => {
      console.log(errors);
    },
  );

  return {
    ...form,
    handleSubmit,
  };
}
