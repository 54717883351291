import {
  validateAccountNumber,
  validateDriversLicense,
  validateEmail,
  validatePassword,
  validatePhone,
  validatePinCode,
  validateSSN,
} from '../../util/form-validation';
import { convertValidationRules } from '../../hooks/useFormState';

export type Model = {
  email: string;
  password: string;
  phoneNumber: string;
  accountNumber: string;
  ssn: string;
  driversLicense: string;
  pinCode: string;
  paperlessBilling: string;
  primaryAccountOption: string;
  secondaryAccountOption: string;
};

export default convertValidationRules<Model>(context => {
  const { primaryAccountOption, secondaryAccountOption } = context.values;
  return {
    // this must match the names of the form fields
    email: validateEmail,
    password: validatePassword,
    accountNumber:
      primaryAccountOption === 'accountNumber' ? validateAccountNumber : null,
    phoneNumber: primaryAccountOption === 'phoneNumber' ? validatePhone : null,
    ssn: secondaryAccountOption === 'ssn' ? validateSSN : null,
    driversLicense:
      secondaryAccountOption === 'driversLicense'
        ? validateDriversLicense
        : null,
    pinCode: validatePinCode,
  };
});
