import gql from 'not-graphql-tag';

export const registerResidentialMutation = gql`
  mutation registerResidential($payload: ResidentialRegistrationRequest!) {
    registerResidential(payload: $payload) {
      success
      uid
      signinToken
    }
  }
`;
export const unregisterMutation = gql`
  mutation unregister {
    unregister {
      success
    }
  }
`;
