import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import colors from '../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        flexDirection: 'row',
        display: 'flex',
      },
      '& .MuiFormControl-root': {
        display: 'flex',
        flexDirection: 'column',
      },
      '& .MuiGrid-justify-xs-center': {
        justifyContent: 'flex-start',
      },
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    paper: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: -1,
    },
    submit: {
      marginTop: '10px',
      margin: theme.spacing(2, 0, 2),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: 'auto',
      },
      backgroundColor: colors.orange,
      borderColor: colors.orange,
      '&:hover': {
        backgroundColor: colors.orange,
        borderColor: colors.orange,
      },
    },
    card: {
      // minWidth: 275,
      justifyContent: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      width: '100%',
      color: colors.noirBlur,
    },
    spacing: {
      padding: '1em',
    },
    radioSubtitle: {
      marginLeft: 40,
    },
    or: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginTop: '2em',
      fontWeight: 'normal',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: '0.5em',
        marginBottom: '0.5em',
        width: '100%',
        textAlign: 'center',
      },
    },
    and: {
      paddingBottom: '1em',
      width: '50%',
      textAlign: 'center',
      fontWeight: 'normal',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '1.5em',
        marginBottom: '1.5em',
      },
    },
    backButton: {
      [theme.breakpoints.up('md')]: {
        marginTop: '1em',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: '0em',
      },
    },
    buttonWrapper: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    buttonContainer: {
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    backLinkDesktop: {
      display: 'inline-block',
      marginRight: 20,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    backLinkMobile: {
      display: 'none',
      margin: 20,
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    overflowField: {
      '& label': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        right: 0,
        paddingRight: 24,
        overflow: 'hidden',
      },
    },
  }),
);

export default useStyles;
